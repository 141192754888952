<template>
  <div class="h-screen flex w-full bg-full-grey">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block lg:w-1/2
                mx-auto
                self-center
              "
            >
              <img
                src="@/assets/images/pages/reset-password.png"
                alt="login"
                class="mx-auto main-img"
              />
            </div>
            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                self-center
                d-theme-dark-bg
              "
            >
              <div class="px-8 pt-12 pb-12">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset Password</h4>
                  <p>Please enter your new password.</p>
                </div>
                <vs-input
                  v-model="email"
                  v-validate="'required|email'"
                  type="email"
                  name="email"
                  label-placeholder="Email"
                  class="w-full"
                />
                <v-error :err="errors" field="email" />

                <vs-input
                  v-model="password"
                  v-validate="'required|min:8'"
                  type="password"
                  name="password"
                  label-placeholder="Password"
                  class="w-full mt-6"
                />
                <v-error :err="errors" field="password" />

                <vs-input
                  v-model="password_confirmation"
                  v-validate="'required|min:8'"
                  type="password"
                  name="password_confirmation"
                  label-placeholder="Confirm Password"
                  class="w-full mt-6"
                />
                <v-error :err="errors" field="password_confirmation" />

                <div
                  class="
                    flex flex-wrap
                    justify-between
                    flex-col-reverse
                    sm:flex-row
                    mt-6
                  "
                >
                  <vs-button
                    type="border"
                    to="/login"
                    class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                  >
                    Go back to login
                  </vs-button>
                  <vs-button
                    :disabled="!validateForm"
                    class="w-full sm:w-auto"
                    @click="reset"
                  >
                    Reset
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { getMessageFromError } from '@/helpers';
import { AuthAction } from '@/store/actionTypes';

import vError from '@/components/VError';

export default {
  components: {
    vError,
  },
  data: () => ({
    email: '',
    password: '',
    password_confirmation: '',
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.email !== '' &&
        this.password !== '' &&
        this.password_confirmation !== ''
      );
    },
  },
  methods: {
    ...mapActions('auth', [AuthAction.resetPassword]),

    async reset() {
      const payload = {
        token: this.$route.query.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      if (!payload.token) {
        this.notifyError(null, 'Token is not valid.');

        return;
      }

      this.$vs.loading();

      try {
        const { message } = await this.resetPassword(payload);
        this.notifySuccess('Password reset', message);

        this.$router.push({ name: 'login' }).catch();
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-img {
  width: 60%;
}
</style>
